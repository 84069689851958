import {
    Autocomplete,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import BtnClose from './BtnClose';
import Grid from '@mui/material/Grid2';
import {LoadingButton} from '@mui/lab';
import {useAuth} from '../hooks/useAuth';
import {AlertContext, api} from '../services';
import {Controller, useForm} from 'react-hook-form';
import {useContext, useEffect, useState} from 'react';

function CreateOrEditLubricant({lubricant, setLubricant, loadLubricants, company}) {
    const theme = useTheme();
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const {
        reset,
        control,
        register,
        setError,
        handleSubmit,
        formState: {errors},
    } = useForm();

    const handleClose = () => setLubricant({...lubricant, open: false})

    function onSubmit(data) {
        setLoading(true);

        let apiMethod = api.post;
        let apiEndpoint = '/lubricants';

        if (lubricant?.data?.id) {
            apiMethod = api.put;
            apiEndpoint += '/' + lubricant?.data?.id;
        }

        let form = {
            type: data.type,
            description: data.description,
            unit_of_measurement: data.unit_of_measurement,
        }

        if (company) {
            form.price = {
                [company.id]: data.price,
            }
        }

        apiMethod(apiEndpoint, form)
            .then(response => {
                handleClose();
                newAlert(response?.data?.message, 'success');
                loadLubricants();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) {
                    Object.entries(error.response?.data?.errors)
                        .forEach(([key, message]) => {
                            setError(key, {
                                type: 422,
                                message: message[0],
                            });
                        });
                } else {
                    const action = lubricant?.data?.id ? 'editar' : 'criar';

                    newAlert(error.response?.data?.message ?? `Erro ao tentar ${action} o lubrificante. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    function handleChange(type) {
        if (!type) return;

        reset({
            type,
            description: `${type} `,
            unit_of_measurement: type === 'Graxa Lubrificante' ? 'Kg' : 'L',
        });
    }

    useEffect(() => {
        if (lubricant.open) {
            if (!lubricant?.data?.id) {
                reset({
                    type: null,
                    description: '',
                    unit_of_measurement: null,
                    price: '',
                });
            } else {
                reset({
                    type: lubricant.data.type,
                    description: lubricant.data.description,
                    unit_of_measurement: lubricant.data.unit_of_measurement,
                    price: company ? lubricant.data.price : null,
                });
            }

        }
        // eslint-disable-next-line
    }, [lubricant]);

    return (
        <Dialog
            maxWidth="md"
            open={Boolean(lubricant?.open)}
            onClose={handleClose}
            fullScreen={fullScreen}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
        >
            <DialogTitle>
                <Box>
                    {lubricant?.data?.id ? 'Editar' : 'Novo'} Lubrificante
                </Box>
                <BtnClose onClick={handleClose}/>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container columnSpacing={7}>
                    <Grid size={{xs: 12, sm: 6}}>
                        <Controller
                            name="type"
                            control={control}
                            defaultValue={null}
                            rules={{required: 'O tipo do lubrificante é obrigatório.'}}
                            render={({field}) => (
                                <Autocomplete
                                    fullWidth
                                    value={field.value}
                                    noOptionsText="Nenhum tipo"
                                    onChange={(event, value) => {
                                        handleChange(value);
                                        field.onChange(value);
                                    }}
                                    options={[
                                        'Graxa Lubrificante',
                                        'Fluído de Transmissão',
                                        'Fluído de Corte',
                                        'Fluído de Freio',
                                        'Fluído de Dielétrico',
                                        'Fluído Refrigerante',
                                        'Óleo Hidráulico Mineral',
                                        'Óleo Hidráulico Sintético',
                                        'Óleo Lubrificante Monoviscoso',
                                        'Óleo Lubrificante para Guias e Barramentos',
                                        'Óleo Lubrificante para Transmissão',
                                        'Óleo Mineral',
                                        'Óleo para Bomba de Vácuo',
                                        'Óleo para Turbinas',
                                        'Oléo Lubrificante Mineral',
                                        'Oléo para Transmissão Universal',
                                        'Spray Antioxidante',
                                        'Óleo Penetrante',
                                        'Óleo Refrigerante',
                                        'Óleo SAE',
                                    ]}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            margin="normal"
                                            name="type"
                                            label="Tipo"
                                            error={Boolean(errors?.type)}
                                            helperText={errors?.type?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}}>
                        <FormControl className="flex flex-col">
                            <Typography
                                variant="h6"
                                className="font-medium text-sm mb-1"
                            >
                                Unidade de Medida
                            </Typography>
                            <Controller
                                name="unit_of_measurement"
                                control={control}
                                defaultValue={null}
                                rules={{required: 'A unidade de medida é obrigatória.'}}
                                render={({field}) => (
                                    <RadioGroup row {...field} className="mt-3">
                                        <FormControlLabel
                                            value="L"
                                            label="Litro (L)"
                                            control={<Radio/>}
                                            slotProps={{
                                                typography: {
                                                    variant: 'body1',
                                                    className: 'font-regular',
                                                },
                                            }}
                                        />
                                        <FormControlLabel
                                            value="Kg"
                                            control={<Radio/>}
                                            label="Quilograma (Kg)"
                                            slotProps={{
                                                typography: {
                                                    variant: 'body1',
                                                    className: 'font-regular',
                                                },
                                            }}
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid size={12}>
                        <TextField
                            fullWidth
                            type="text"
                            margin="normal"
                            label="Descrição"
                            error={Boolean(errors?.description)}
                            helperText={errors?.description?.message}
                            {...register('description', {
                                required: 'A descrição do lubrificante é obrigatória.',
                            })}
                        />
                    </Grid>
                </Grid>
                {
                    company && (
                        <Grid size={12} className="mt-4">
                            <Typography className="text-xl font-medium mb-1.5">
                                Preço
                            </Typography>
                            <Divider/>
                            <Box className="w-100 text-center">
                                <TextField
                                    type="number"
                                    margin="normal"
                                    className="mt-3 text-left"
                                    label={company.name}
                                    error={Boolean(errors?.price)}
                                    helperText={errors?.price?.message}
                                    {...register('price', {valueAsNumber: true,})}
                                    slotProps={{
                                        htmlInput: {
                                            min: 0,
                                            step: 0.01,
                                        },
                                        input: {
                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                        },
                                    }}
                                />
                            </Box>
                        </Grid>
                    )
                }
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    type="submit"
                    className="px-12"
                    loading={loading}
                    disabled={Object.keys(errors).length > 0}
                >
                    Salvar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default CreateOrEditLubricant;

export {CreateOrEditLubricant};