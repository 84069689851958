import {styled} from '@mui/system';
import {useLocation} from 'react-router';
import {useAuth} from '../hooks/useAuth';

const Main = styled('main', {shouldForwardProp: (prop) => !['isMobile', 'open'].includes(prop)})(
    ({theme, open, isMobile}) => {
        const local = useLocation()
        const {authenticated} = useAuth();

        return {
            flexGrow: 1,
            minHeight: local.pathname === '/login' ? '100vh' : 'calc(100vh - 64px)',
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            ...(!isMobile && authenticated && open && {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                [theme.breakpoints.not('xs')]: {
                    marginLeft: '280px',
                },
            }),
            ...(!isMobile && authenticated && !open && {
                width: 'calc(100% - 100px)',
                [theme.breakpoints.not('xs')]: {
                    marginLeft: '100px',
                },
            }),
            ...((isMobile || !authenticated) && {
                width: '100%',
            }),
        };
    },
);

export default Main;

export {Main};