import clsx from 'clsx';
import useAuth from '../../hooks/useAuth';
import {forwardRef} from 'react';
import {Box, Button, Divider, Tooltip, Typography} from '@mui/material';
import {TreeItem2Icon, TreeItem2Provider, useTreeItem2} from '@mui/x-tree-view';
import {
    CustomTreeItemContent,
    CustomTreeItemGroupTransition,
    CustomTreeItemIconContainer,
    CustomTreeItemRoot
} from './CustomTreeItem';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const StyledTreeItemGoTo = forwardRef((props, ref) => {
    const {
        itemId,
        label,
        children,
        disabled,
        ...other
    } = props;

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        getGroupTransitionProps,
        status,
        publicAPI,
    } = useTreeItem2({itemId, children, label, disabled, rootRef: ref});

    const {id, name} = publicAPI.getItem(itemId);
    const {setWorkspace, setSelectWorkspace} = useAuth();

    let LabelIcon = FolderIcon;

    if (id === 0) {
        LabelIcon = HomeIcon;
    }

    return (
        <TreeItem2Provider itemId={itemId}>
            <CustomTreeItemRoot {...getRootProps(other)} isConfiguring={false}>
                <CustomTreeItemContent
                    {...getContentProps({
                        className: clsx('content gap-0', {
                            'Mui-expanded': status.expanded,
                            'Mui-focused': status.focused,
                            'Mui-disabled': status.disabled,
                        }),
                    })}
                >
                    <CustomTreeItemIconContainer className="mr-0" {...getIconContainerProps()}>
                        <TreeItem2Icon status={status}/>
                    </CustomTreeItemIconContainer>
                    <Divider className="w-[16px] h-[2px] -ml-[1.4px]" sx={{borderBottom: '2px solid #fff'}}/>
                    <Box
                        sx={{borderRadius: '.5rem 0 0 .5rem',}}
                        className="flex grow items-center py-[9px] pl-4 pr-0.5 bg-white"
                    >
                        <Box component={LabelIcon} color="text.secondary" className="mr-1" sx={{fontSize: 30}}/>
                        <Typography
                            {
                                ...getLabelProps({
                                    variant: 'body2',
                                    className: 'grow flex items-end font-medium'
                                })
                            }
                        />
                    </Box>
                    <Tooltip title={'Ir para ' + name}>
                        <Button
                            className="ms-auto p-0 w-[48px] h-[48px] bg-green-btn"
                            onClick={() => {
                                setWorkspace(id);
                                setSelectWorkspace(false);
                            }}
                            sx={{
                                minWidth: 48,
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                marginLeft: 1,
                            }}
                        >
                            <ArrowForwardIcon
                                className="rounded-sm p-0.5"
                                sx={{fontSize: 27, border: '2px solid #fff'}}
                            />
                        </Button>
                    </Tooltip>
                </CustomTreeItemContent>
                {children && (
                    <CustomTreeItemGroupTransition {...getGroupTransitionProps()} black={false}/>
                )}
            </CustomTreeItemRoot>
        </TreeItem2Provider>
    );
});

export default StyledTreeItemGoTo;

export {StyledTreeItemGoTo};