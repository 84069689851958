const adjustWith = ({theme, open, authenticated, isMobile}) => ({
    transitionProperty: 'width, margin-left',
    transitionEasing: theme.transitions.easing.easeOut,
    transitionDuration: theme.transitions.duration.enteringScreen,
    ...(!isMobile && authenticated && open && {
        width: 'calc(100% - 280px)',
        [theme.breakpoints.not('xs')]: {
            marginLeft: '280px',
        },
    }),
    ...(!isMobile && authenticated && !open && {
        width: 'calc(100% - 100px)',
        [theme.breakpoints.not('xs')]: {
            marginLeft: '100px',
        },
    }),
    ...((isMobile || !authenticated) && {
        width: '100%',
    }),
});

export default adjustWith;

export {adjustWith};