import {api} from '../services';
import {Controller} from 'react-hook-form';
import {useEffect, useRef, useState} from 'react';
import {Autocomplete, TextField} from '@mui/material';
import Grid from '@mui/material/Grid2';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import FolderCopyIcon from '@mui/icons-material/FolderCopyOutlined';

function FindFolder({folder, company, name, control}) {
    const timeoutSearch = useRef(null);
    const [query, setQuery] = useState('');
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleDisableSubmit = e => e.keyCode === 13 && e.preventDefault() && setQuery(search);

    const handleTimeoutSearch = () => {
        clearTimeout(timeoutSearch.current);
        timeoutSearch.current = setTimeout(() => setQuery(search), 1000);
    };

    useEffect(() => {
        if (!query || !company) {
            let newOptions = [];

            if (company) {
                if (!folder || folder.id !== company?.id) {
                    newOptions = [{id: company.id, name: 'TODAS', parent: company.name}];
                }

                if (folder) {
                    newOptions = [...newOptions, folder];
                }
            }

            setOptions(newOptions);
            return undefined;
        }

        setLoading(true);

        api
            .get(`/folders/${company.id}/subfolders`, {
                params: {
                    query,
                }
            })
            .then(response => {
                let newOptions = [];

                if (response.data) {
                    newOptions = [...response.data.data];
                }

                setOptions(newOptions);
            })
            .finally(() => setLoading(false));
    }, [folder, query, company]);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={null}
            render={({field}) => (
                <Autocomplete
                    options={options}
                    loading={loading}
                    value={field.value}
                    filterOptions={x => x}
                    groupBy={option => option.parent}
                    getOptionLabel={option => option.name}
                    onInputChange={(event, value) => setSearch(value)}
                    noOptionsText={company ? query ? 'Nenhuma pasta encontrada' : 'Digite o nome ou id da pasta' : 'Selecione primeiro a empresa'}
                    onChange={(event, value) => {
                        setQuery('');
                        field.onChange(value);
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            fullWidth
                            label="Pasta"
                            margin="normal"
                            onKeyUp={handleTimeoutSearch}
                            onKeyDown={handleDisableSubmit}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={props.key}>
                            <Grid container alignItems="center">
                                <Grid sx={{display: 'flex', width: 44}}>
                                    {option.id === company.id ? <FolderCopyIcon/> : <FolderIcon/>}
                                </Grid>
                                <Grid
                                    sx={{
                                        fontWeight: option.id === company.id ? 'bolder' : 'normal',
                                        width: 'calc(100% - 44px)',
                                        wordWrap: 'break-word',
                                    }}
                                >
                                    {option.name}
                                </Grid>
                            </Grid>
                        </li>
                    )}
                />
            )}
        />
    );
}

export default FindFolder;

export {FindFolder};