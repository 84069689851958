import {useContext, useEffect} from 'react';
import {Navigate} from 'react-router';
import {useAuth} from '../../hooks/useAuth';
import {AlertContext} from '../../services';
import {CircularProgress} from '@mui/material';

function RequireAdmin({children}) {
    const {loading, user} = useAuth();
    const {newAlert} = useContext(AlertContext);

    useEffect(() => {
        if (!loading && !user.is_admin) {
            newAlert('Você não tem permissão para acessar essa página.');
        }
        // eslint-disable-next-line
    }, [loading, user]);

    if (loading) return <CircularProgress className="m-auto"/>;

    if (!user.is_admin) {
        return <Navigate to="../home"/>;
    }

    return children;
}

export default RequireAdmin;

export {RequireAdmin};