import {useLocation, useNavigate} from 'react-router';
import {ListItem as ListItemMUI, ListItemButton, ListItemIcon, ListItemText, styled, Tooltip} from '@mui/material';

const StyledListItemButton = styled(ListItemButton)(({theme}) => ({
    minHeight: 48,
    '&:hover': {
        fontWeight: 500,
        background: theme.palette.action.gradient.hover,
    },
    '&.Mui-selected': {
        fontWeight: 600,
        background: theme.palette.action.gradient.selected,
    },
    '&:hover, &.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
        paddingLeft: 12,
        paddingRight: 16,
        color: theme.palette.primary.main,

        borderLeft: `4px solid ${theme.palette.primary.main}`,
    },
}));

function ListItem({text, Icon, route, regEx, showText, onClick = () => null}) {
    const local = useLocation();
    const navigate = useNavigate();
    const regex = new RegExp(regEx ?? route);

    return (
        <ListItemMUI
            key={text}
            disablePadding
            sx={{borderBottom: 'none',}}
            onClick={() => {
                onClick();
                navigate(route);
            }}
        >
            <Tooltip title={!showText && text} placement="right">
                <StyledListItemButton selected={regex.test(local.pathname)}
                                      className={!showText && 'flex justify-center'}>
                    {
                        showText ? (
                            <>
                                <ListItemIcon>
                                    <Icon/>
                                </ListItemIcon>
                                <ListItemText primary={text} slotProps={{primary: {fontWeight: 'inherit'}}}/>
                            </>
                        ) : <Icon className="mr-1"/>
                    }
                </StyledListItemButton>
            </Tooltip>
        </ListItemMUI>
    );
}

export default ListItem;

export {ListItem};