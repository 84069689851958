import {useContext, useEffect} from 'react';
import {useAuth} from '../../hooks/useAuth';
import {AlertContext} from '../../services';
import {CircularProgress} from '@mui/material';
import {Navigate, useLocation} from 'react-router';

function RequireAuth({children}) {
    const local = useLocation();
    const {newAlert} = useContext(AlertContext);
    const {loading, authenticated, setRedirect} = useAuth();

    useEffect(() => {
        if (!loading && !authenticated) {
            setRedirect(local.pathname + local.search);
            newAlert('Faça login para acessar a página solicitada.', 'warning');
        }
        // eslint-disable-next-line
    }, [loading, authenticated]);


    if (loading) return <CircularProgress className="m-auto"/>;

    if (!authenticated) {
        return <Navigate to="../login"/>;
    }

    return children;
}

export default RequireAuth;

export {RequireAuth};