import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    TextField,
    useMediaQuery,
    useTheme
} from '@mui/material';
import BtnClose from './BtnClose';
import Grid from '@mui/material/Grid2';
import {LoadingButton} from '@mui/lab';
import {useForm} from 'react-hook-form';
import {useAuth} from '../hooks/useAuth';
import {AlertContext, api} from '../services';
import {useContext, useEffect, useState} from 'react';

function DefineOrEditConsumption({consumption, setConsumption, loadConsumptions}) {
    const theme = useTheme();
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const {
        reset,
        register,
        setError,
        handleSubmit,
        formState: {errors},
    } = useForm();

    const handleClose = () => setConsumption({...consumption, open: false})

    function onSubmit(data) {
        setLoading(true);

        api.put(`/consumptions/${consumption?.data?.id}`, data)
            .then(response => {
                handleClose();
                newAlert(response?.data?.message, 'success');
                loadConsumptions();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) {
                    Object.entries(error.response?.data?.errors)
                        .forEach(([key, message]) => {
                            setError(key, {
                                type: 422,
                                message: message[0],
                            });
                        });
                } else {
                    const action = consumption?.data?.quantity ? 'alterar' : 'definir';

                    newAlert(error.response?.data?.message ?? `Erro ao tentar ${action} o consumo. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (consumption.open) {
            reset({
                quantity: consumption?.data?.quantity ?? '',
            });
        }
        // eslint-disable-next-line
    }, [consumption]);

    return (
        <Dialog
            maxWidth="md"
            open={Boolean(consumption?.open)}
            onClose={handleClose}
            fullScreen={fullScreen}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
        >
            <DialogTitle>
                <Box>
                    {consumption?.data?.quantity ? 'Alterar' : 'Definir'} Consumo
                </Box>
                <BtnClose
                    onClick={() => {
                        handleClose();

                        if (window.location.hash) {
                            window.location.href = '#';
                        }
                    }}
                />
            </DialogTitle>
            <DialogContent dividers>
                <Grid size={12}>
                    <Box className="w-100 text-center">
                        <TextField
                            type="number"
                            margin="normal"
                            className="mt-3 text-left"
                            label="Quantidade reposta"
                            error={Boolean(errors?.quantity)}
                            helperText={errors?.quantity?.message}
                            {...register('quantity', {
                                valueAsNumber: true,
                                required: 'A quantidade reposta é obrigatória.',
                            })}
                            slotProps={{
                                htmlInput: {
                                    min: 1,
                                    step: 0.01,
                                },
                                input: {
                                    endAdornment: <InputAdornment position="end">ml</InputAdornment>,
                                },
                            }}
                        />
                    </Box>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    type="submit"
                    className="px-12"
                    loading={loading}
                    disabled={Object.keys(errors).length > 0}
                >
                    Salvar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default DefineOrEditConsumption;

export {DefineOrEditConsumption};