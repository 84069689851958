import {api} from '../services';
import {Controller} from 'react-hook-form';
import {useEffect, useRef, useState} from 'react';
import {Autocomplete, TextField} from '@mui/material';
import Grid from '@mui/material/Grid2';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

function FindCompany({company, name, control, setFolder}) {
    const timeoutSearch = useRef(null);
    const [query, setQuery] = useState('');
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleDisableSubmit = e => e.keyCode === 13 && e.preventDefault() && setQuery(search);

    const handleTimeoutSearch = () => {
        clearTimeout(timeoutSearch.current);
        timeoutSearch.current = setTimeout(() => setQuery(search), 1000);
    };

    useEffect(() => {
        if (!query) {
            let newOptions = [];

            if (company) {
                newOptions = [company];
            }

            setOptions(newOptions);
            return undefined;
        }

        setLoading(true);

        api
            .get('/folders/search', {
                params: {
                    query,
                }
            })
            .then(response => {
                let newOptions = [];

                if (response.data) {
                    newOptions = [...response.data.data];
                }

                setOptions(newOptions);
            })
            .finally(() => setLoading(false));
    }, [company, query]);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={null}
            render={({field}) => (
                <Autocomplete
                    options={options}
                    loading={loading}
                    value={field.value}
                    filterOptions={x => x}
                    getOptionLabel={option => option.name}
                    onInputChange={(event, value) => setSearch(value)}
                    noOptionsText={query ? 'Nenhuma empresa encontrada' : 'Digite o nome ou id da empresa'}
                    onChange={(event, value) => {
                        setQuery('');
                        field.onChange(value);
                        setFolder(null);
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            fullWidth
                            label="Empresa"
                            margin="normal"
                            onKeyUp={handleTimeoutSearch}
                            onKeyDown={handleDisableSubmit}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={props.key}>
                            <Grid container alignItems="center">
                                <Grid sx={{display: 'flex', width: 44}}>
                                    <CorporateFareIcon/>
                                </Grid>
                                <Grid sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
                                    {option.name}
                                </Grid>
                            </Grid>
                        </li>
                    )}
                />
            )}
        />
    );
}

export default FindCompany;

export {FindCompany};