import {api} from '../services';
import {useEffect, useRef, useState} from 'react';
import {Controller} from 'react-hook-form';
import {Autocomplete, TextField, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

function FindUser({user, control}) {
    const timeoutSearch = useRef(null);
    const [email, setEmail] = useState('');
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleDisableSubmit = e => e.keyCode === 13 && e.preventDefault() && setEmail(search);

    const handleTimeoutSearch = () => {
        clearTimeout(timeoutSearch.current);
        timeoutSearch.current = setTimeout(() => setEmail(search), 1000);
    };

    useEffect(() => {
        if (!email) {
            let newOptions = [];

            if (user) {
                newOptions = [user];
            }

            setOptions(newOptions);
            return undefined;
        }

        setLoading(true);

        api
            .get('/users/find', {
                params: {
                    email,
                }
            })
            .then(response => {
                let newOptions = [];

                if (response.data) {
                    newOptions = [...response.data.data];
                }

                setOptions(newOptions);
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line
    }, [user, email]);

    return (
        <Controller
            name="user"
            control={control}
            defaultValue={null}
            render={({field}) => (
                <Autocomplete
                    options={options}
                    loading={loading}
                    value={field.value}
                    filterOptions={x => x}
                    getOptionLabel={option => option.name}
                    onInputChange={(event, value) => setSearch(value)}
                    noOptionsText={email ? 'Nenhum usuário encontrado' : 'Digite o e-mail do usuário'}
                    onChange={(event, value) => {
                        setEmail('');
                        field.onChange(value);
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            fullWidth
                            label="Usuário"
                            margin="normal"
                            onKeyUp={handleTimeoutSearch}
                            onKeyDown={handleDisableSubmit}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={props.key}>
                            <Grid container alignItems="center">
                                <Grid sx={{display: 'flex', width: 44}}>
                                    <PersonOutlinedIcon/>
                                </Grid>
                                <Grid sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
                                    <Grid container direction="column">
                                        <Grid>
                                            {option.name}
                                        </Grid>
                                        <Grid>
                                            <Typography variant="caption text-slate-400	">
                                                {option.email}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </li>
                    )}
                />
            )}
        />
    );
}

export default FindUser;

export {FindUser};