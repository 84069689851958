import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import dayjs from 'dayjs';
import {useAuth} from '../hooks';
import BtnClose from './BtnClose';
import {LoadingButton} from '@mui/lab';
import Grid from '@mui/material/Grid2';
import {useContext, useEffect, useState} from 'react';
import {AlertContext, api} from '../services';
import {Controller, useForm} from 'react-hook-form';
import {exportReportErrorPDF, exportReportErrorXLS} from '../utils';
import {ExcelIcon, PDFIcon} from '../icons';
import AirIcon from '@mui/icons-material/Air';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import WaterDropIcon from '@mui/icons-material/WaterDropOutlined';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';

function ExportErrorReport({open, handleClose}) {
    const theme = useTheme();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const {workspace, workspaces, handleLogout} = useAuth();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const {
        reset,
        watch,
        control,
        setError,
        handleSubmit,
        formState: {errors},
    } = useForm({
        defaultValues: {
            types: {
                active: true,
                solved: true,
            },
            monitors: {
                lubricator: true,
                breather: true,
                'level_display': true,
            },
            start_date: dayjs().subtract(1, 'month'),
            end_date: dayjs(),
        }
    });

    const types = watch('types');
    const monitors = watch('monitors');
    const startDate = watch('start_date');

    function handleError(error, name) {
        const errors = {
            invalidDate: 'Data inválida.',
            minDate: 'A data inicial não pode ser maior que a final.',
        };

        if (typeof errors[error] !== 'undefined') {
            setError(name, {
                type: error,
                message: errors[error],
            });
        }
    }

    function onSubmit(data) {
        setLoading(true)

        const typesKeys = [];
        const monitorsKeys = [];

        Object.entries(types).forEach(([type, active]) => {
            if (active) typesKeys.push(type);
        });

        Object.entries(monitors).forEach(([monitor, active]) => {
            if (active) monitorsKeys.push(monitor);
        });

        api
            .get('/reports/error', {
                params: {
                    workspace,
                    types: typesKeys,
                    format: data.format,
                    monitors: monitorsKeys,
                    start_date: startDate.format('YYYY-MM-DD'),
                    end_date: data.end_date.format('YYYY-MM-DD'),
                }
            })
            .then(response => {
                response.data.data.types = types;
                response.data.data.name = workspaces[workspace];
                response.data.data.start = startDate.format('DD/MM/YYYY');
                response.data.data.end = data.end_date.format('DD/MM/YYYY');

                if (data.format === 'pdf') {
                    response.data.data.id = workspace;
                    response.data.data.moment = dayjs().format('DD/MM/YYYY HH:mm:ss');

                    exportReportErrorPDF(response.data.data);
                } else {
                    exportReportErrorXLS(response.data.data);
                }
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    newAlert(error.response?.data?.message ?? `Erro ao tentar gerar relatório. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (open) {
            reset({
                types: {
                    active: true,
                    solved: true,
                },
                monitors: {
                    lubricator: true,
                    breather: true,
                    'level_display': true,
                },
                start_date: dayjs().subtract(1, 'month'),
                end_date: dayjs(),
            });
        }
        // eslint-disable-next-line
    }, [open]);

    return (
        <Dialog
            maxWidth="md"
            open={open}
            fullScreen={fullScreen}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
        >
            <DialogTitle>
                <Box>
                    Exportar Relatório
                </Box>
                <BtnClose onClick={handleClose}/>
            </DialogTitle>
            <DialogContent dividers className="px-10">
                <Typography variant="h6" className="font-medium text-lg mb-8">
                    Filtrar Alertas
                </Typography>
                <Grid container className="justify-center mb-12" spacing={4}>
                    <Controller
                        name="start_date"
                        control={control}
                        render={({field}) => (
                            <DatePicker
                                disableFuture
                                name="start_date"
                                label="Data Inicial"
                                value={field.value}
                                onChange={startDate => field.onChange(startDate)}
                                onError={error => handleError(error, 'start_date')}
                                slotProps={{
                                    textField: {
                                        helperText: errors?.start_date?.message,
                                    },
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="end_date"
                        control={control}
                        render={({field}) => (
                            <DatePicker
                                disableFuture
                                name="end_date"
                                label="Data Final"
                                minDate={startDate}
                                value={field.value}
                                onChange={startDate => field.onChange(startDate)}
                                onError={error => handleError(error, 'end_date')}
                                slotProps={{
                                    textField: {
                                        helperText: errors?.end_date?.message,
                                    },
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid container className="justify-between" spacing={2}>
                    <Grid className="flex flex-col">
                        <Controller
                            name="types.active"
                            control={control}
                            render={({field}) => (
                                <FormControlLabel
                                    label="Exibir Alertas Ativos"
                                    sx={{cursor: (types.active && !types.solved ? 'not-allowed' : 'pointer'),}}
                                    control={
                                        <Checkbox
                                            size="small"
                                            {...field}
                                            checked={field.value}
                                            readOnly={types.active && !types.solved}
                                            onChange={e => !types.active || types.solved ? field.onChange(e.target.checked) : null}
                                            sx={{cursor: (types.active && !types.solved ? 'not-allowed' : 'pointer'),}}
                                        />
                                    }
                                    slotProps={{
                                        typography: {
                                            variant: 'body1',
                                            className: 'font-regular',
                                        },
                                    }}
                                />
                            )}
                        />
                        <Typography variant="caption" className="-mt-2">
                            Incluir alertas ativos.
                        </Typography>
                    </Grid>
                    <Grid className="flex flex-col">
                        <Controller
                            name="types.solved"
                            control={control}
                            render={({field}) => (
                                <FormControlLabel
                                    label="Exibir Alertas Solucionados"
                                    sx={{cursor: (types.solved && !types.active ? 'not-allowed' : 'pointer'),}}
                                    control={
                                        <Checkbox
                                            size="small"
                                            {...field}
                                            checked={field.value}
                                            readOnly={types.solved && !types.active}
                                            onChange={e => !types.solved || types.active ? field.onChange(e.target.checked) : null}
                                            sx={{cursor: (types.solved && !types.active ? 'not-allowed' : 'pointer'),}}
                                        />
                                    }
                                    slotProps={{
                                        typography: {
                                            variant: 'body1',
                                            className: 'font-regular',
                                        },
                                    }}
                                />
                            )}
                        />
                        <Typography variant="caption" className="-mt-2">
                            Incluir alertas que foram resolvidos.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className="justify-between mt-12" spacing={2}>
                    <Grid container direction="column">
                        <Typography variant="h6" className="font-medium text-lg mb-5">
                            Filtrar Componentes
                        </Typography>
                        <Controller
                            name="monitors.lubricator"
                            control={control}
                            render={({field}) => (
                                <FormControlLabel
                                    sx={{cursor: monitors.lubricator && !monitors.breather && !monitors.level_display ? 'not-allowed' : 'pointer',}}
                                    label={
                                        <Grid container className="items-center">
                                            Lubrificador
                                            <WaterDropIcon className="ml-1" sx={{fontSize: '16px'}}/>
                                        </Grid>
                                    }
                                    control={
                                        <Checkbox
                                            size="small"
                                            {...field}
                                            checked={field.value}
                                            readOnly={monitors.lubricator && !monitors.breather && !monitors.level_display}
                                            sx={{
                                                cursor: monitors.lubricator && !monitors.breather && !monitors.level_display ? 'not-allowed' : 'pointer',
                                            }}
                                            onChange={e => {
                                                if (!monitors.lubricator || monitors.breather || monitors.level_display) {
                                                    field.onChange(e.target.checked)
                                                }
                                            }}
                                        />
                                    }
                                    slotProps={{
                                        typography: {
                                            variant: 'body1',
                                            className: 'font-regular',
                                        },
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="monitors.breather"
                            control={control}
                            render={({field}) => (
                                <FormControlLabel
                                    sx={{cursor: monitors.breather && !monitors.lubricator && !monitors.level_display ? 'not-allowed' : 'pointer',}}
                                    label={
                                        <Grid container className="items-center">
                                            Respiro
                                            <AirIcon className="ml-1" sx={{fontSize: '16px'}}/>
                                        </Grid>
                                    }
                                    control={
                                        <Checkbox
                                            size="small"
                                            {...field}
                                            checked={field.value}
                                            readOnly={monitors.breather && !monitors.lubricator && !monitors.level_display}
                                            sx={{
                                                cursor: monitors.breather && !monitors.lubricator && !monitors.level_display ? 'not-allowed' : 'pointer',
                                            }}
                                            onChange={e => {
                                                if (!monitors.breather || monitors.lubricator || monitors.level_display) {
                                                    field.onChange(e.target.checked);
                                                }
                                            }}
                                        />
                                    }
                                    slotProps={{
                                        typography: {
                                            variant: 'body1',
                                            className: 'font-regular',
                                        },
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="monitors.level_display"
                            control={control}
                            render={({field}) => (
                                <FormControlLabel
                                    sx={{cursor: monitors.level_display && !monitors.lubricator && !monitors.breather ? 'not-allowed' : 'pointer',}}
                                    label={
                                        <Grid container className="items-center">
                                            Visor de Nível
                                            <DeviceThermostatIcon className="ml-1" sx={{fontSize: '16px'}}/>
                                        </Grid>
                                    }
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={field.value}
                                            readOnly={monitors.level_display && !monitors.lubricator && !monitors.breather}
                                            sx={{
                                                cursor: monitors.level_display && !monitors.lubricator && !monitors.breather ? 'not-allowed' : 'pointer',
                                            }}
                                            onChange={e => {
                                                if (!monitors.level_display || monitors.lubricator || monitors.breather) {
                                                    field.onChange(e.target.checked);
                                                }
                                            }}

                                        />
                                    }
                                    slotProps={{
                                        typography: {
                                            variant: 'body1',
                                            className: 'font-regular',
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <FormControl className="flex flex-col">
                        <Typography
                            variant="h6"
                            component="label"
                            htmlFor="formats-export"
                            className="font-medium text-lg mb-5"
                        >
                            Formato de Exportação
                        </Typography>
                        <Controller
                            name="format"
                            control={control}
                            defaultValue="pdf"
                            render={({field}) => (
                                <RadioGroup {...field}>
                                    <FormControlLabel
                                        value="pdf"
                                        control={<Radio/>}
                                        label={
                                            <Grid container className="items-center">
                                                PDF
                                                <PDFIcon className="ml-1" sx={{fontSize: '16px'}}/>
                                            </Grid>
                                        }
                                        slotProps={{
                                            typography: {
                                                variant: 'body1',
                                                className: 'font-regular',
                                            },
                                        }}
                                    />
                                    <FormControlLabel
                                        value="xls"
                                        control={<Radio/>}
                                        label={
                                            <Grid container className="items-center">
                                                Excel
                                                <ExcelIcon className="ml-1" sx={{fontSize: '16px'}}/>
                                            </Grid>
                                        }
                                        slotProps={{
                                            typography: {
                                                variant: 'body1',
                                                className: 'font-regular',
                                            },
                                        }}
                                    />
                                </RadioGroup>
                            )}
                        />
                    </FormControl>
                    <canvas
                        id="chart"
                        width="400"
                        height="400"
                        style={{
                            maxWidth: 300,
                            maxHeight: 300,
                            display: 'none',
                        }}
                    >
                    </canvas>
                </Grid>
            </DialogContent>
            <DialogActions className="justify-between px-10">
                <Button
                    color="error"
                    variant="outlined"
                    onClick={handleClose}
                >
                    Cancelar
                </Button>
                <LoadingButton
                    type="submit"
                    loading={loading}
                    variant="outlined"
                    disabled={Object.keys(errors).length > 0}
                >
                    Baixar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default ExportErrorReport;

export {ExportErrorReport};