import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {useAuth} from '../hooks';
import Grid from '@mui/material/Grid2';
import {useEffect, useState} from 'react';
import getItemIds from '../utils/getItemIds';
import {RichTreeView} from '@mui/x-tree-view';
import {StyledTreeItemGoTo} from './tree/StyledTreeItemGoTo';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

function SelectWorkspace({selectWorkspace, setSelectWorkspace}) {
    const theme = useTheme();
    const {treeWorkspaces} = useAuth();
    const [allItems, setAllItems] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const handleSelectClose = () => setSelectWorkspace(false);
    const handleExpandAll = () => setExpanded(expanded.length >= allItems.length ? [] : allItems);

    useEffect(() => {
        setAllItems(getItemIds(treeWorkspaces));
        setExpanded(treeWorkspaces.map(folder => `folder-${folder?.id}`));
        // eslint-disable-next-line
    }, [treeWorkspaces]);

    return (
        <Dialog
            maxWidth="lg"
            open={selectWorkspace}
            fullScreen={fullScreen}
            onClose={handleSelectClose}
            PaperProps={{
                className: 'bg-primary-bg text-white',
            }}
        >
            <DialogTitle className="px-3 px-md-6 px-lg-12 pt-8">
                <Box className="grow text-center">
                    Painel de Controle
                </Box>
                <Button
                    variant="outlined"
                    className="bg-white px-11 py-[3.75px] hidden sm:block"
                    onClick={handleExpandAll}
                    disabled={allItems.length === 0}
                >
                    {allItems.length === expanded.length ? 'Recolher' : 'Expandir'}
                </Button>
                <Box>
                    <Tooltip title="Fechar">
                        <IconButton
                            size="large"
                            onClick={handleSelectClose}
                        >
                            <DisabledByDefaultOutlinedIcon className="text-white"/>
                        </IconButton>
                    </Tooltip>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container justifyContent="end" className="p-3 flex sm:hidden">
                    <Button
                        variant="outlined"
                        className="bg-white px-11 py-[3.75px]"
                        onClick={handleExpandAll}
                        disabled={allItems.length === 0}
                    >
                        {allItems.length === expanded.length ? 'Recolher' : 'Expandir'}
                    </Button>
                </Grid>
                <Grid container className="p-3">
                    <RichTreeView
                        disableSelection
                        aria-label="Painel de Controle"
                        className="w-full overflow-auto ml-3"
                        expandedItems={expanded}
                        onExpandedItemsChange={(event, itemIds) => setExpanded(itemIds)}
                        items={treeWorkspaces ?? []}
                        getItemId={item => `${item.type}-${item.id}`}
                        getItemLabel={item => item.name}
                        slots={{
                            item: StyledTreeItemGoTo,
                            expandIcon: () => <AddBoxOutlinedIcon className="text-white"/>,
                            collapseIcon: () => <IndeterminateCheckBoxOutlinedIcon className="text-white"/>,
                            endIcon: () => <DisabledByDefaultOutlinedIcon className="text-white opacity-50"/>,
                        }}
                    />
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default SelectWorkspace;

export {SelectWorkspace};