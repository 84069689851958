import {Alert, AlertTitle, Box, styled} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';

const iconMapping = {
    success: (
        <Box className="bg-white rounded-full p-[3px] h-[24px] w-[24px] text-success">
            <CheckIcon sx={{fontSize: 18}} className="mb-2"/>
        </Box>
    ),
    warning: (
        <Box className="bg-white rounded-full p-[3px] h-[24px] w-[24px] text-warning">
            <WarningIcon sx={{fontSize: 18}} className="mb-2"/>
        </Box>
    ),
    error: (
        <Box className="bg-white rounded-full p-[3px] h-[24px] w-[24px] text-error">
            <WarningIcon sx={{fontSize: 18}} className="mb-2"/>
        </Box>
    ),
    info: (
        <Box className="bg-white rounded-full p-[3px] h-[24px] w-[24px] text-primary">
            <InfoIcon sx={{fontSize: 18}} className="mb-2"/>
        </Box>
    ),
};

const StyledAlert = styled(props => (
    <Alert icon={false} iconMapping={iconMapping} {...props} />
))(({severity, theme}) => ({
    marginTop: 0,
    fontSize: '12px',
    padding: '9px 15px',
    marginBottom: '2rem',
    borderRadius: '0.5rem',
    border: '1px solid #DDD',
    background: `linear-gradient(to bottom, ${theme.palette[severity].alert}, #FFF)`,

    '&.MuiAlertTitle-root': {
        fontSize: '12px',
    },
}));

const StyledAlertTitle = styled(AlertTitle)(({theme}) => ({
    display: 'flex',
    fontSize: '12px',
    columnGap: '5px',
    alignItems: 'center',
    marginBottom: '14px',
}));

export {StyledAlert, StyledAlertTitle, iconMapping};