import {styled} from '@mui/system';
import {AppBar as AppBarMUI} from '@mui/material';
import adjustWith from '../utils/adjustWith';

const AppBar = styled(AppBarMUI, {
    shouldForwardProp: (prop) => !['open', 'isMobile', 'authenticated'].includes(prop)
})(adjustWith);

export default AppBar;

export {AppBar};