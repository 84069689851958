import {useState} from 'react';
import {api} from '../services';
import {phoneMask} from '../utils';
import Grid from '@mui/material/Grid2';
import {LoadingButton} from '@mui/lab';
import useAuth from '../hooks/useAuth';
import BtnShowPassword from './BtnShowPassword';
import {Controller, useForm} from 'react-hook-form';
import {Checkbox, FormControlLabel, Link, TextField, Typography} from '@mui/material';

function UpdateAccount({newAlert}) {
    const [showPassword, setShowPassword] = useState(false);
    const {handleLogout, user, setUser, setLoadingBackdrop} = useAuth();
    const [disableUpdateAccountBtn, setDisableUpdateAccountBtn] = useState(false);
    const {
        reset,
        watch,
        control,
        register,
        setError,
        handleSubmit,
        formState: {errors},
    } = useForm({
        defaultValues: {
            name: user?.name,
            email: user?.email,
            phone: phoneMask(user?.phone),
        }
    });

    const changeEmail = watch('changeEmail');
    const phone_notifications = watch('phone_notifications');

    function handlePhone(event) {
        const input = event.target;

        input.value = phoneMask(input.value);
    }

    function handleCancelChange() {
        setLoadingBackdrop(true);

        api.patch('/email/cancel-change')
            .then(response => {
                setUser(user => {
                    let newUser = {...user};

                    newUser.new_email = null;

                    return newUser
                });

                newAlert(response?.data?.message, 'success');
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar cancelar alteração. Tente novamente mais tarde.');
                }
            })
            .finally(setLoadingBackdrop(false));
    }

    function onSubmit(data) {
        setDisableUpdateAccountBtn(true);

        const phone = data.phone.replace(/[^0-9]/g, '');

        let form = {
            name: data.name,
            email_notifications: data.email_notifications,
            phone,
            phone_notifications,
        };

        if (changeEmail) {
            form.email = data.email;
            form.password = data.password;
        }

        api.patch('/update-account', form)
            .then(response => {
                newAlert(response?.data?.message, 'success');

                setUser(user => {
                    let newUser = {...user};

                    newUser.name = data.name;
                    newUser.email_notifications = data.email_notifications;
                    newUser.phone_notifications = data.phone_notifications;
                    if (changeEmail) newUser.new_email = data.email;

                    return newUser
                });

                if (changeEmail) reset({'password': ''});
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) {
                    Object.entries(error.response?.data?.errors)
                        .forEach(([key, message]) => {
                            setError(key, {
                                type: 422,
                                message: message[0],
                            });
                        });
                } else {
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar atualizar informações. Tente novamente mais tarde.');
                }
            })
            .finally(() => setDisableUpdateAccountBtn(false));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                size={12}
                className="p-2"
                rowSpacing={{xs: 3, lg: 5}}
            >
                <Grid
                    container
                    size={12}
                    className="justify-between items-center mt-3"
                    rowSpacing={0}
                    columnSpacing={4}
                >
                    <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center">
                        <Typography className="font-medium" component="label" htmlFor="name">
                            Nome
                        </Typography>
                        <Typography variant="body2" component="div" className="hidden lg:block">
                            Adicione seu nome
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12, lg: 6}} className="flex items-center">
                        <TextField
                            autoFocus
                            fullWidth
                            id="name"
                            type="text"
                            margin="normal"
                            placeholder="Nome"
                            autoComplete="name"
                            error={Boolean(errors?.name)}
                            helperText={errors?.name?.message}
                            {...register('name', {
                                required: 'O nome é obrigatório.',
                                minLength: {
                                    value: 3,
                                    message: 'O nome precisa ter pelo menos 3 caracteres.',
                                }
                            })}
                        />
                    </Grid>
                </Grid>
                <Grid container size={12} className="justify-between items-center" rowSpacing={0} columnSpacing={4}>
                    <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center">
                        <Typography className="font-medium" component="label" htmlFor="email">
                            E-mail
                        </Typography>
                        <Typography variant="body2" component="div" className="hidden lg:block">
                            Adicione seu e-mail
                        </Typography>
                        <Controller
                            name="email_notifications"
                            control={control}
                            defaultValue={user?.email_notifications}
                            render={({field}) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            onChange={(e) => field.onChange(e.target.checked)}
                                        />
                                    }
                                    label="Desejo receber notificações por e-mail"
                                />
                            )}
                        />
                    </Grid>
                    <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center items-end">
                        <TextField
                            fullWidth
                            id="email"
                            type="email"
                            margin="normal"
                            placeholder="E-mail"
                            disabled={!changeEmail}
                            autoComplete="username email"
                            error={Boolean(errors?.email)}
                            {...register('email', {
                                required: 'O e-mail é obrigatório.',
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: 'E-mail inválido.'
                                }
                            })}
                            helperText={
                                errors?.email?.message ?? (
                                    user?.new_email ?
                                        (
                                            <>
                                                O e-mail {user?.new_email} está pendente de confirmação.&nbsp;
                                                <Link
                                                    color="error"
                                                    underline="none"
                                                    component="button"
                                                    type="button"
                                                    onClick={handleCancelChange}
                                                >
                                                    Cancelar alteração
                                                </Link>
                                            </>
                                        ) :
                                        null
                                )
                            }
                        />
                        <Controller
                            name="changeEmail"
                            control={control}
                            defaultValue={false}
                            render={({field}) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            onChange={(e) => field.onChange(e.target.checked)}
                                        />
                                    }
                                    label="Editar Email"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                {
                    changeEmail && (
                        <Grid container size={12} className="justify-between items-center" rowSpacing={0} columnSpacing={4}>
                            <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center">
                                <Typography className="font-medium" component="label" htmlFor="password">
                                    Senha
                                </Typography>
                                <Typography variant="body2" component="div" className="hidden lg:block">
                                    Confirme sua senha
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center items-end">
                                <TextField
                                    fullWidth
                                    id="password"
                                    margin="normal"
                                    placeholder="Senha atual"
                                    type={showPassword ? 'text' : 'password'}
                                    autoComplete="current-password"
                                    error={Boolean(errors?.password)}
                                    helperText={errors?.password?.message}
                                    slotProps={{
                                        input: {
                                            endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                                        },
                                    }}
                                    {...register('password', {
                                        required: 'A senha é obrigatória.',
                                        minLength: {
                                            value: 8,
                                            message: 'A senha precisa ter pelo menos 8 caracteres.',
                                        },
                                    })}
                                />
                            </Grid>
                        </Grid>
                    )
                }
                <Grid container size={12} className="justify-between items-center" rowSpacing={0} columnSpacing={4}>
                    <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center">
                        <Typography className="font-medium" component="label" htmlFor="phone">
                            Telefone
                        </Typography>
                        <Typography variant="body2" component="div" className="hidden lg:block">
                            Adicione seu telefone
                        </Typography>
                        <Controller
                            name="phone_notifications"
                            control={control}
                            defaultValue={user?.phone_notifications}
                            render={({field}) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            onChange={(e) => field.onChange(e.target.checked)}
                                        />
                                    }
                                    label="Desejo receber notificações por WhatsApp ou SMS"
                                />
                            )}
                        />
                    </Grid>
                    <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center items-end">
                        <TextField
                            fullWidth
                            id="phone"
                            type="tel"
                            margin="normal"
                            placeholder="Telefone"
                            autoComplete="tel-national"
                            onKeyUp={handlePhone}
                            error={Boolean(errors?.phone)}
                            helperText={errors?.phone?.message}
                            {...register('phone', {
                                required: phone_notifications ? 'O telefone é obrigatório se deseja receber notificações.' : false,
                                minLength: {
                                    value: 15,
                                    message: 'O telefone precisa ter 11 dígitos.',
                                },
                                maxLength: {
                                    value: 15,
                                    message: 'O telefone precisa ter 11 dígitos.',
                                },
                            })}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className="justify-end p-2 mt-1">
                <LoadingButton
                    type="submit"
                    loading={disableUpdateAccountBtn}
                    disabled={Object.keys(errors).length > 0}
                >
                    Salvar
                </LoadingButton>
            </Grid>
        </form>
    );
}

export default UpdateAccount;

export {UpdateAccount};