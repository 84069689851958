import {
    styled,
    TableCell,
    tableCellClasses,
    TableRow,
    TableSortLabel as TableSortLabelMUI,
    tableSortLabelClasses
} from '@mui/material';

const StyledTableCell = styled(TableCell)(({theme}) => ({
    height: 53,
    [`&.${tableCellClasses.head}`]: {
        fontSize: 16,
        fontWeight: theme.typography.fontWeightMedium,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)({
    borderColor: '#CACACA',
    backgroundColor: '#EFF3F6',
    '&:hover': {
        backgroundColor: '#fff',
    },
});

const StyledTableSortLabel = styled(TableSortLabelMUI)(({theme}) => ({
    [`&.${tableSortLabelClasses.active}, &.${tableSortLabelClasses.root}:hover, &.${tableSortLabelClasses.active}:focus`]: {
        color: theme.palette.primary.main,
    },
    '& .MuiTableSortLabel-icon': {
        color: `${theme.palette.primary.main} !important`,
    },
}));

function TableSortLabel({label, text, short = true, order, setOrder, orderBy, setOrderBy}) {
    function handleClick(column) {
        const isAsc = orderBy === column && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(column);
    }

    return (
        <StyledTableCell
            key={label}
            align="center"
            sortDirection={orderBy === label ? order : false}
        >
            {
                short ?
                    (
                        <StyledTableSortLabel
                            active={orderBy === label}
                            direction={orderBy === label ? order : 'asc'}
                            onClick={() => handleClick(label)}
                        >
                            {text}
                        </StyledTableSortLabel>
                    ) : text
            }
        </StyledTableCell>
    );
}

export {StyledTableCell, StyledTableRow, TableSortLabel};