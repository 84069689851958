import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    TextField,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {useAuth} from '../hooks';
import BtnClose from './BtnClose';
import Grid from '@mui/material/Grid2';
import {LoadingButton} from '@mui/lab';
import {useForm} from 'react-hook-form';
import {AlertContext, api} from '../services';
import {useContext, useEffect, useState} from 'react';

function DetailsLubricantPrices({lubricantPrices, setLubricantPrices, loadLubricants, companies}) {
    const theme = useTheme();
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        reset,
        register,
        handleSubmit,
    } = useForm();

    const handleClose = () => setLubricantPrices({...lubricantPrices, open: false})

    function onSubmit(data) {
        setLoading(true);

        api.patch(`/lubricants/${lubricantPrices.data.id}/prices`, data)
            .then(response => {
                handleClose();
                newAlert(response?.data?.message, 'success');
                loadLubricants();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    newAlert(error.response?.data?.message ?? `Erro ao tentar atualizar preços do lubrificante. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (lubricantPrices.open) {
            reset({
                prices: lubricantPrices.data.prices,
            });
        }

        // eslint-disable-next-line
    }, [lubricantPrices]);

    return (
        <Dialog
            maxWidth="sm"
            open={Boolean(lubricantPrices?.open)}
            onClose={handleClose}
            fullScreen={fullScreen}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
        >
            <DialogTitle>
                <Box>
                    Preços do Lubrificante
                </Box>
                <BtnClose onClick={handleClose}/>
            </DialogTitle>
            <DialogContent dividers>
                {
                    companies.map(company => (
                        <Grid size={12} className="mt-4 text-center" key={company.id}>
                            <TextField
                                type="number"
                                margin="normal"
                                className="mt-3 text-left"
                                label={company.name}
                                {...register(`prices.${company.id}`, {valueAsNumber: true,})}
                                slotProps={{
                                    htmlInput: {
                                        min: 0,
                                        step: 0.01,
                                    },
                                    input: {
                                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                    },
                                }}
                            />
                        </Grid>
                    ))
                }
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    type="submit"
                    className="px-12"
                    loading={loading}
                >
                    Salvar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default DetailsLubricantPrices;

export {DetailsLubricantPrices};