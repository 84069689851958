import {styled} from '@mui/material/styles';
import {
    TreeItem2Content,
    TreeItem2GroupTransition,
    TreeItem2IconContainer,
    TreeItem2Root
} from '@mui/x-tree-view/TreeItem2';

const CustomTreeItemRoot = styled(TreeItem2Root, {shouldForwardProp: prop => prop !== 'isConfiguring'})
(({theme, isConfiguring}) => ({
    margin: '1rem 0',
    width: isConfiguring ? 335 : 295,
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
        width: isConfiguring ? 385 : 345,
    },
}));

const CustomTreeItemContent = styled(TreeItem2Content)(({theme}) => ({
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),

    '&:hover': {
        backgroundColor: 'inherit',
    },
    '&.Mui-focused': {
        backgroundColor: 'inherit',
        color: 'inherit',
    },
}));

const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    marginRight: theme.spacing(1),
}));

const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition, {shouldForwardProp: prop => prop !== 'black'})
(({theme, black = true}) => ({
    marginLeft: 7,
    marginTop: -18,
    paddingTop: 18,
    paddingLeft: 31,
    borderLeft: `2px solid ${black ? theme.palette.text.primary : '#FFF'}`,
}));

export {CustomTreeItemRoot, CustomTreeItemContent, CustomTreeItemIconContainer, CustomTreeItemGroupTransition};