function moneyFormatter(number, minimumFractionDigits = 0, currency = 'BRL') {
    if (typeof number !== 'number') {
        number = parseFloat(number);
    }

    return number.toLocaleString('pt-BR', {style: 'currency', currency, minimumFractionDigits});
}

export default moneyFormatter;

export {moneyFormatter};