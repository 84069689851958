import {useAuth} from '../hooks';
import {styled} from '@mui/system';
import Box from '@mui/material/Box';
import adjustWith from '../utils/adjustWith';
import {useNotifications} from '../hooks/useNotifications';
import {Button, Fade as FadeMUI, Paper, Stack, Typography, Unstable_TrapFocus as TrapFocus} from '@mui/material';

function DialogNotifications({open, isMobile}) {
    const {user, authenticated} = useAuth();
    const {request, setRequest, sendTokenToServer} = useNotifications();
    const Fade = styled(FadeMUI, {
        shouldForwardProp: prop => ![ 'open', 'isMobile', 'authenticated'].includes(prop)
    })(adjustWith);
    const closeBanner = () => setRequest(false);

    const requestPermission = () => (
        new Promise((resolve, reject) => (
            Notification.requestPermission()
                .then(permission => {
                    if (permission === 'granted') {
                        sendTokenToServer()
                            .then(resolve)
                            .catch(reject);
                    }
                })
                .catch(reject)
        ))
    );

    if (!authenticated) return;

    return (
        <TrapFocus open disableAutoFocus disableEnforceFocus>
            <Fade
                open={open}
                in={request}
                appear={false}
                isMobile={isMobile}
                authenticated={authenticated}
            >
                <Paper
                    square
                    role="dialog"
                    aria-modal="false"
                    aria-label="Notificações Push"
                    variant="outlined"
                    tabIndex={-1}
                    sx={{
                        m: 0,
                        p: 2,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 1,
                        borderWidth: 0,
                        position: 'fixed',
                        borderTopWidth: 1,
                    }}
                >
                    <Stack
                        gap={2}
                        justifyContent="space-between"
                        direction={{xs: 'column', md: 'row'}}
                    >
                        <Box className="flex-shrink xs:self-start md:self-center">
                            <Typography fontWeight="bold">Quer receber notificações?</Typography>
                            <Typography variant="body2">
                                Seja notificado nesse dispositivo em tempo real sobre futuros alertas.
                            </Typography>
                        </Box>
                        <Stack
                            gap={2}
                            direction="row"
                            className="xs:self-end md:self-center"
                            justifyContent={{xs: 'center', md: 'end'}}
                        >
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                    closeBanner();
                                    localStorage.setItem('requestNotifications', 'false');
                                }}
                            >
                                Agora não
                            </Button>
                            <Button
                                size="small"
                                onClick={() => {
                                    closeBanner();
                                    requestPermission()
                                        .then(() => sessionStorage.setItem('notifications_' + user.id, 'true'))
                                        .catch(console.error);
                                }}
                            >
                                Receber
                            </Button>
                        </Stack>
                    </Stack>
                </Paper>
            </Fade>
        </TrapFocus>
    );
}

export default DialogNotifications;

export {DialogNotifications};