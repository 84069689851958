import {
    Alert,
    Button,
    IconButton,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import env from 'react-dotenv';
import {useAuth} from '../hooks';
import Grid from '@mui/material/Grid2';
import {useLocation} from 'react-router';
import {AlertContext, api} from '../services';
import {toLocaleString} from '../utils/toLocaleString';
import {useContext, useEffect, useRef, useState} from 'react';
import {DefineOrEditConsumption, RefreshedAt, StyledTableCell, StyledTableRow, TablePagination} from './';
import EditIcon from '@mui/icons-material/EditOutlined';

function LatestConsumptions({lubricator, levelDisplay, unit_of_measurement}) {
    const {hash} = useLocation();
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [consumptions, setConsumptions] = useState([]);
    const [consumption, setConsumption] = useState({open: false});
    const interval = parseInt(localStorage.getItem('refresh') ?? env.REFRESH_DEFAULT_INTERVAL) * 60 * 1000;
    let timeout = useRef(null);
    let monitor = lubricator ? 'lubrificador' : 'visor de nível';

    function loadConsumptions() {
        clearTimeout(timeout.current);
        setLoading(true);

        api
            .get('/consumptions', {
                params: {
                    lubricator: lubricator,
                    level_display: levelDisplay,
                    page: page + 1,
                    per_page: perPage,
                }
            })
            .then(response => {
                setConsumptions(response?.data);
                timeout.current = setTimeout(loadConsumptions, interval);
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 404) newAlert(`O ${monitor} solicitado não foi encontrado.`);
                else if (code === 403) newAlert(`Você não tem permissão para acessar os consumos desse ${monitor}.`);
                else newAlert(`Erro ao tentar carregar os consumos desse ${monitor}. Tente novamente mais tarde.`);

                setConsumptions([]);
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        loadConsumptions();

        return () => clearTimeout(timeout.current);
        // eslint-disable-next-line
    }, [lubricator, levelDisplay, page, perPage]);


    useEffect(() => {
        if (hash.startsWith('#consumption-')) {
            setConsumption({
                open: true,
                data: {
                    id: parseInt(hash.split('-')[1]),
                },
            })
        }
    }, [hash])

    return (
        <>
            <Grid size={12}>
                <RefreshedAt
                    className="self-start mb-3"
                    hour={consumptions.hour}
                    refresh={loadConsumptions}
                />
                <TableContainer component={Paper}>
                    <Table
                        stickyHeader
                        aria-label="Últimos Consumos"
                        sx={(theme) => ({
                            minWidth: 250,
                            border: `1px solid ${theme.palette.primary.main}`,
                        })}
                        size="small"
                        className="p-1 rounded-lg"
                    >
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Quantidade</StyledTableCell>
                                <StyledTableCell>Data</StyledTableCell>
                                <StyledTableCell>Hora</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                loading ? (
                                    new Array(perPage).fill(1).map((value, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell>
                                                <Skeleton width={75}/>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Skeleton width={75}/>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Skeleton width={60}/>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                ) : consumptions?.data?.map(consumption => (
                                    <StyledTableRow key={consumption?.id}>
                                        <StyledTableCell>
                                            {
                                                consumption?.quantity ? (
                                                    <>
                                                        {toLocaleString(consumption.quantity)}&nbsp;
                                                        {unit_of_measurement === 'L' ? 'ml' : 'g'}
                                                        {
                                                            levelDisplay && (
                                                                <Tooltip title="Editar">
                                                                    <IconButton
                                                                        color="warning"
                                                                        aria-label="Editar"
                                                                        onClick={() => setConsumption({
                                                                            open: true,
                                                                            data: {
                                                                                id: consumption.id,
                                                                                quantity: consumption.quantity,
                                                                            },
                                                                        })}
                                                                    >
                                                                        <EditIcon/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )
                                                        }
                                                    </>
                                                ) : (
                                                    <Button
                                                        variant="outlined"
                                                        className="py-1 px-8 text-xs rounded"
                                                        onClick={() => setConsumption({
                                                            open: true,
                                                            data: {
                                                                id: consumption.id
                                                            },
                                                        })}
                                                    >
                                                        Definir
                                                    </Button>
                                                )
                                            }
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {consumption?.date}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {consumption?.time}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))
                            }
                            {
                                !loading && !consumptions?.data?.length && (
                                    <TableRow>
                                        <TableCell colSpan={3} align="center">
                                            <Alert severity="warning">
                                                Nenhum consumo encontrado para esse {monitor}.
                                            </Alert>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={3}
                                    rowsPerPage={perPage}
                                    setPerPage={setPerPage}
                                    page={page}
                                    setPage={setPage}
                                    count={consumptions?.meta?.total ?? 0}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>
            <DefineOrEditConsumption
                consumption={consumption}
                setConsumption={setConsumption}
                loadConsumptions={loadConsumptions}
            />
        </>
    );
}

export default LatestConsumptions;

export {LatestConsumptions};