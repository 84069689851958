import env from 'react-dotenv';
import {useEffect} from 'react';
import {Link} from 'react-router';
import Logo from '../images/menu.webp';
import {useAuth} from '../hooks/useAuth';
import GetLUB from '../images/GetLUB.webp';
import LogoCLI from '../images/logo-cli.webp';
import {DrawerHeader, ListItem, SelectWorkspace} from './';
import {Box, Button, Divider, Drawer, List, ListItem as ListItemMUI, Tooltip, Typography,} from '@mui/material';
import AirIcon from '@mui/icons-material/Air';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import WaterDropIcon from '@mui/icons-material/WaterDropOutlined';
import OpacityOutlinedIcon from '@mui/icons-material/OpacityOutlined';
import AccountTreeIcon from '@mui/icons-material/AccountTreeOutlined';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';

function Aside({isMobile, open, openMobile, handleDrawerClose, handleDrawerTransitionEnd}) {
    const {can, user, workspace, workspaces, selectWorkspace, setSelectWorkspace} = useAuth();

    const handleSelectToggle = () => setSelectWorkspace(!selectWorkspace);
    const handleClick = () => isMobile ? handleDrawerClose() : null;

    // eslint-disable-next-line
    useEffect(() => handleDrawerClose, []);

    return (
        <>
            {
                selectWorkspace && (
                    <SelectWorkspace
                        selectWorkspace={selectWorkspace}
                        setSelectWorkspace={setSelectWorkspace}
                    />
                )
            }
            <Box
                component="aside"
                sx={{width: 280, flexShrink: 0}}
                aria-label="menu"
            >
                <Drawer
                    open={isMobile ? openMobile : open}
                    variant={isMobile ? 'temporary' : 'permanent'}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    sx={{
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: open || isMobile ? 280 : 100,
                        },
                    }}
                >
                    <DrawerHeader>
                        <Link to="../" className="grow flex justify-center align-center">
                            {
                                isMobile || open ? (
                                    <img
                                        src={Logo}
                                        alt={env?.APP_TITLE}
                                        height={35}
                                    />
                                ) : (
                                    <img
                                        src={GetLUB}
                                        alt={env?.APP_TITLE}
                                        height={35}
                                    />
                                )
                            }
                        </Link>
                    </DrawerHeader>
                    <Divider/>
                    <List>
                        <ListItemMUI key={0} className="px-3">
                            {
                                isMobile || open ? (
                                    <Button
                                        fullWidth
                                        variant="text"
                                        className="flex items-center justify-around rounded-xl px-5 py-3.5 my-2 mx-3 bg-grey-bg"
                                        sx={theme => ({
                                            border: `1px solid ${theme.palette.primary.main}`,
                                            color: 'text.primary',
                                        })}
                                        onClick={handleSelectToggle}
                                    >

                                        <Box className="flex flex-col grow">
                                            <Typography
                                                component="div"
                                                variant="body2"
                                                align="left"
                                            >
                                                Painel de Controle
                                            </Typography>
                                            <Typography
                                                component="div"
                                                variant="body1"
                                                className="font-semibold"
                                                align="left"
                                            >
                                                {workspaces[workspace]}
                                            </Typography>
                                        </Box>
                                        <Box className="flex-none flex items-center">
                                            <UnfoldMoreIcon fontSize="large"/>
                                        </Box>
                                    </Button>
                                ) : (
                                    <Tooltip title="Painel de Controle" placement="right">
                                        <Button variant="text" onClick={handleSelectToggle} className="my-3">
                                            <UnfoldMoreIcon fontSize="large"/>
                                        </Button>
                                    </Tooltip>
                                )
                            }
                        </ListItemMUI>
                    </List>
                    <List className="pt-0">
                        <ListItem
                            text="Página Inicial"
                            Icon={HomeIcon}
                            route="/home"
                            onClick={handleClick}
                            showText={isMobile || open}
                        />
                        <ListItem
                            text="Árvore de Ativos"
                            Icon={AccountTreeIcon}
                            route="/assets-tree"
                            onClick={handleClick}
                            showText={isMobile || open}
                        />
                        <ListItem
                            text="Lubrificadores"
                            Icon={WaterDropIcon}
                            route="/lubricators"
                            regEx="^/lubricators/?[0-9]*$"
                            onClick={handleClick}
                            showText={isMobile || open}
                        />
                        <ListItem
                            text="Respiros"
                            Icon={AirIcon}
                            route="/breathers"
                            regEx="^/breathers/?[0-9]*$"
                            onClick={handleClick}
                            showText={isMobile || open}
                        />
                        <ListItem
                            text="Visores de Nível"
                            Icon={DeviceThermostatIcon}
                            route="/level-displays"
                            regEx="^/level-displays/?[0-9]*$"
                            onClick={handleClick}
                            showText={isMobile || open}
                        />
                        {
                            can('view_lubricant_costs') && (
                                <>
                                    <ListItem
                                        text="Lubrificantes"
                                        Icon={OpacityOutlinedIcon}
                                        route="/lubricants"
                                        onClick={handleClick}
                                        showText={isMobile || open}
                                    />
                                    <ListItem
                                        text="Dashboard"
                                        Icon={BarChartIcon}
                                        route="/dashboard"
                                        onClick={handleClick}
                                        showText={isMobile || open}
                                    />
                                </>
                            )
                        }
                    </List>
                    {
                        user?.is_admin && (
                            <>
                                <Divider
                                    color="primary.main"
                                    className="mt-auto font-medium text-primary-main"
                                    sx={theme => ({
                                        '&::before, &::after': {
                                            borderTop: `1px solid ${theme.palette.primary.main}`,
                                        },
                                    })}
                                >
                                    {(isMobile || open) && 'Gerenciar'}
                                </Divider>
                                <List className="mt-auto">
                                    <ListItem
                                        text="Empresas"
                                        Icon={CorporateFareIcon}
                                        route="/companies"
                                        onClick={handleClick}
                                        showText={isMobile || open}
                                    />
                                    <ListItem
                                        text="Usuários"
                                        Icon={GroupIcon}
                                        route="/users"
                                        onClick={handleClick}
                                        showText={isMobile || open}
                                    />
                                    <ListItem
                                        text="Permissões"
                                        Icon={LockOpenIcon}
                                        route="/roles"
                                        onClick={handleClick}
                                        showText={isMobile || open}
                                    />
                                    <ListItem
                                        text="Lubrificadores"
                                        Icon={WaterDropIcon}
                                        route="/lubricators/manage"
                                        onClick={handleClick}
                                        showText={isMobile || open}
                                    />
                                    <ListItem
                                        text="Respiros"
                                        Icon={AirIcon}
                                        route="/breathers/manage"
                                        onClick={handleClick}
                                        showText={isMobile || open}
                                    />
                                    <ListItem
                                        text="Visores de Nível"
                                        Icon={DeviceThermostatIcon}
                                        route="/level-displays/manage"
                                        onClick={handleClick}
                                        showText={isMobile || open}
                                    />
                                </List>
                            </>
                        )
                    }
                    <Divider className="mt-auto"/>
                    <List>
                        <ListItemMUI key={11} className="flex justify-center">
                            {
                                (isMobile || open) && (
                                    <Typography variant="body2" className="mr-2" color="primary.dark">
                                        Desenvolvido por
                                    </Typography>
                                )
                            }
                            <a
                                href="https://www.clisolucoes.com.br/"
                                target="_blank"
                                rel="noreferrer"
                                className="flex items-center"
                            >
                                <img
                                    src={LogoCLI}
                                    alt="Cli Soluções e Confiabilidade"
                                />
                            </a>
                        </ListItemMUI>
                    </List>
                </Drawer>
            </Box>
        </>
    );
}

export default Aside;

export {Aside};