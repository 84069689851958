import Grid from '@mui/material/Grid2';
import RefreshedAt from './RefreshedAt';
import {useForm} from 'react-hook-form';
import {Button, InputAdornment, Paper, TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';

function FilterRecords({
                           setQuery,
                           loading,
                           setPage,
                           placeholder,
                           hour,
                           refresh,
                           variant = 'outlined',
                           onFilter = null
                       }) {
    const {
        register,
        handleSubmit,
    } = useForm();

    const handleSearch = data => {
        setQuery(data.search);
        setPage(0);
    };

    return (
        <Grid
            container
            className="rounded-xl py-4 px-10 bg-white mb-7"
            component={Paper}
        >
            <Grid
                container
                size={12}
                component="form"
                className="items-center sm:justify-between justify-center"
                onSubmit={handleSubmit(handleSearch)}
                spacing={2}
            >
                <Grid size={{xs: 12, lg: onFilter ? 6 : 7.5}}>
                    <TextField
                        autoFocus
                        fullWidth
                        className="rounded-2xl p-0"
                        placeholder={placeholder}
                        {...register('search')}
                        slotProps={{
                            input: {
                                sx: {border: '1px solid #CACACA'},
                                className: 'pl-0 bg-grey-input shadow-none',
                                startAdornment: (
                                    <InputAdornment position="start" className="mx-1.5">
                                        <SearchIcon/>
                                    </InputAdornment>
                                ),
                            },
                            htmlInput: {
                                className: 'py-2 pr-1.5 placeholder:font-light',
                                'aria-label': placeholder,
                            },
                        }}
                    />
                </Grid>
                <Grid size="auto">
                    <Button type="submit" endIcon={<SearchIcon/>} variant={variant}>
                        Pesquisar
                    </Button>
                </Grid>
                {
                    onFilter !== null && (
                        <Grid size="auto">
                            <Button variant="outlined" endIcon={<FilterListIcon/>} onClick={onFilter}>
                                Filtrar
                            </Button>
                        </Grid>
                    )
                }
                <RefreshedAt
                    size="auto"
                    items="center"
                    loading={loading}
                    hour={hour}
                    refresh={refresh}
                />
            </Grid>
        </Grid>
    );
}

export default FilterRecords;

export {FilterRecords};