import {Navigate} from 'react-router';
import {useContext, useEffect} from 'react';
import {useAuth} from '../../hooks/useAuth';
import {AlertContext} from '../../services';
import {CircularProgress} from '@mui/material';

function RequirePermission({permission, children}) {
    const {loading, user, can} = useAuth();
    const {newAlert} = useContext(AlertContext);

    useEffect(() => {
        if (!loading && !can(permission)) {
            newAlert('Você não tem permissão para acessar essa página.');
        }
        // eslint-disable-next-line
    }, [loading, user]);

    if (loading) return <CircularProgress className="m-auto"/>;

    if (!can(permission)) {
        return <Navigate to="../home"/>;
    }

    return children;
}

export default RequirePermission;

export {RequirePermission};